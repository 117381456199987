/* Material */
import useStyles from './styles'
import CardMedia from '@mui/material/CardMedia'

/* Types */
import { imageSrc } from 'src/shared/frontendTypes/images'
import { CSSProperties } from 'react'

/* Functions */
import getImageUrl from 'src/pages/Product/functions/imageUrl'

/* Components */
import noImage from 'src/images/camera.svg'

const ImagesCarousel: React.FC<{
  images:imageSrc[], 
  productID:number, 
  metaTitle:string,
}> = ({ 
  images, 
  productID, 
  metaTitle, 
}) => {
  const classes = useStyles()

  const image = images?.[0]
  const imagePath = getImageUrl(image,productID,'sm')

  const cropped = image && image.color?.a !== 0
  const whiteUncropped = image && image.color?.r === 255 && image.color?.g === 255 && image.color?.b === 255

  const style: CSSProperties = (cropped && !whiteUncropped)
    ? {
      //backgroundSize: 'cover',
    }
    : {
      backgroundSize: 'contain',
      backgroundOrigin: 'content-box',
      padding: '0.7rem',
    }

  return <CardMedia
    className={classes.cardMedia}
    image={imagePath || noImage.src}
    title={metaTitle}
    {...{style}}
  />

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

}

export default ImagesCarousel
