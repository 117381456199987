import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    container: {
      paddingTop: theme.spacing(4),
      '& > hr': {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
      }
    },

    homeTextTitle: {
      //marginTop: theme.spacing(4),
    },

    homeText: {
      //marginBottom: theme.spacing(4),
    },

  })
)

export default useStyles
