import config from 'src/config'
import { Image, imageSrc } from 'src/shared/frontendTypes/images'
import { imageSizes } from 'src/shared/dbTypes/images'

/* Types */
import { hasOwnProperty } from 'src/functions/typeGuards'

const getImageUrl = (image:Image|imageSrc, productId:number, size:imageSizes): string|null => {
  if (!image?.[size]) return null

  const isImageSrc = (image: Image | imageSrc): image is imageSrc =>
    hasOwnProperty(image, 'src')

  if (!isImageSrc(image) || image.src === 'product')
    return `${config.image_server}/product/${productId}/${image?.[size]}`
  
  else
    return `${config.image_server}/product/${productId}/${image.src}/${image?.[size]}`
}

export default getImageUrl