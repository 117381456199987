import { z } from 'zod'
import typeguard from '../typeguard'


import { productsPreviewSchema } from './product'
import {
  filterTreeSchema,
  filterBrandsSchema,
} from './filterTree'


const filterProductsSchema = z.object({
  products: productsPreviewSchema,
  count: z.number(),
  filterTree: filterTreeSchema.optional(),
  filterBrands: filterBrandsSchema.optional(),
})
export type filterProducts = z.infer<typeof filterProductsSchema>
export const isFilterProducts = typeguard<filterProducts>(filterProductsSchema)


const brandSchema = z.object({
  titel: z.string(),
  description: z.string().optional(),
})
export type brand = z.infer<typeof brandSchema>


const menuSchema = z.object({
  titel: z.string(),
})
export type menu = z.infer<typeof menuSchema>


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* Responses */

const filterProductsResponseSchema = z.object({
  filterProducts: filterProductsSchema,
  brand: brandSchema.optional(),
  menu: menuSchema.optional(),
})
export type filterProductsResponse = z.infer<typeof filterProductsResponseSchema>
export const isFilterProductsResponse = typeguard<filterProductsResponse>(filterProductsResponseSchema)

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */