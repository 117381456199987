/**
 * Erzeugt die URL zu einem Produkt relativ zum Host.
 * 
 * @param productId Die Produkt-Seiten werden nur mit der ID aufgerufen.
 * @param alias Nur für SEO-Zwecke in der URL enthalten.
 * @param options Die Optionen werden als Hashtag-Parameter in der URL angehängt.
 * @returns Relative URL zu einem Produkt.
 */
const productUrl = (
  productId: number,
  alias: string,
  options?: number[],
  leadingSlash = true,
) => {
  
  const selectedOptions = (options && options.length > 0) 
    ? `#${options.join('-')}`
    : ''

  const slash = leadingSlash ? '/' : ''

  return `${slash}p/${alias}/${productId}${selectedOptions}`
  
}

export default productUrl