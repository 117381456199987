/* Next */
import Head from 'next/head'

// https://www.npmjs.com/package/react-schemaorg
// https://stackoverflow.com/questions/47417102/schema-org-structured-data-in-visible-elements
import { Product } from 'schema-dts'
import { JsonLd } from 'react-schemaorg'

/* Functions */
import { formatedIsoDate } from 'src/functions/date'
import productUrl from 'src/functions/productUrl'

/* Config */
import shopInfos from 'template/infos'
const { name, url } = shopInfos

interface HeaderProps {
  id: number
  path: string
  metaTitle: string
  descriptionShort: string
}

export const SeoHeader: React.FC<HeaderProps> = ({ id, path, metaTitle, descriptionShort }) => <Head>

  <title>{`${metaTitle} - ${name}`}</title>

  <meta name='description' content={descriptionShort} />

  <link rel='canonical' href={`${url}${productUrl(id,path,undefined,false)}`} />

</Head>

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

interface ProductCombination {
  id: number
  path: string
  name: string
  description: string
  brandTitel: string
  onlyStock?: boolean
  
  attributes?: string
  images: string[]
  price: number
  options?: number[]
  ean?: string
  sku?: string
  stock?: number
}

export const SeoProductCombination: React.FC<ProductCombination> = ({
  id, path, name, description, brandTitel, onlyStock, 
  images, price, attributes, options, ean, sku, stock,
}) => {

  const url = productUrl(id,path,options)

  const nextYear = 
    formatedIsoDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)))

  return <JsonLd<Product> item={{
    '@context': 'https://schema.org',
    '@type': 'Product',

    // TODO:
    // review: {}
    // aggregateRating: {}

    // Produkt-Daten
    name: attributes 
      ? `${name} - ${attributes}` 
      : name,
      
    description,
    image: images,

    // Kombinations-Daten
    gtin:   ean,
    gtin13: ean,
    sku:    sku,
    model:  attributes,
    url,
    
    // Marke
    brand: {
      '@type': 'Brand',
      name: brandTitel,
    },

    // Das Angebot (Kombination)
    offers: {
      '@type': 'Offer',
      price,
      priceCurrency: 'EUR',
      availability:
        onlyStock && stock
          ? 'LimitedAvailability'
          : stock
            ? 'InStock'
            : 'PreOrder'
      ,
      priceValidUntil: nextYear,
      url,
    },
    
    //categorie:
    //color:
    //countryOfOrigin:
  }} />
}
/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */