import { z } from 'zod'
import typeguard from '../typeguard'

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */


const optionSchema = z.object({
  id: z.number(),
  name: z.string(),
  title: z.string(),
  icon: z.string().optional(),
  products: z.number(),
})
export type option = z.infer<typeof optionSchema>
export const isOption = typeguard<option>(optionSchema)


const filterSchema = z.object({
  id: z.number(),
  name: z.string(),
  title: z.string(),
  type: z.enum(['und', 'oder']),
  display: z.enum(['liste', 'checkbox', 'radio', 'icons']),
  options: z.array(optionSchema),
})
export type filter = z.infer<typeof filterSchema>
export const isFilter = typeguard<filter>(filterSchema)


export const filterTreeSchema = z.array(filterSchema)
export type tFilterTree = z.infer<typeof filterTreeSchema>
export const isFilterTree = typeguard<tFilterTree>(filterTreeSchema)

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const filterBrandSchema = z.object({
  id: z.number(),
  title: z.string(),
  products: z.number(),
})
export type tFilterBrand = z.infer<typeof filterBrandSchema>
export const isFilterBrand = typeguard<tFilterBrand>(filterBrandSchema)


export const filterBrandsSchema = z.array(filterBrandSchema)
export type tFilterBrands = z.infer<typeof filterBrandsSchema>
export const isFilterBrands = typeguard<tFilterBrands>(filterBrandsSchema)

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
