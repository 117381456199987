/* React */
import { useState, useEffect } from 'react'

/* Material */
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Types */
import { tProductPreview } from 'src/shared/frontendTypes/product'
import { isFilterProductsResponse } from 'src/shared/frontendTypes/productsFilter'

/* Components */
import Product, { display } from 'src/components/ProductPreview/Product'

/* Functions */
import { post } from 'src/functions/fetch'

/* Options */
import config from 'src/config'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    cardGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gap: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),

      '& a': {
        color: 'inherit',
        textDecoration: 'inherit',
        '& .MuiCardContent-root': {
          paddingBottom: theme.spacing(1),
        }
      },

    },

  })
)

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
 
interface Props {
  keys?: string[]
  size?: number
  display: display[]
}

const Products: React.FC<Props> = ({ display, keys, size=12 }) => {
  const classes = useStyles()

  const [products, setProducts] = useState<tProductPreview[]>([])

  const loadProducts = async (signal:AbortSignal) => {

    const data = await post({
      url:'products',
      body:{
        size,
        keys,
        ...config.shop,
      },
      signal,
    })

    if (!isFilterProductsResponse(data)) {
      if (!signal.aborted) console.error('Konnte Produkte nicht laden!') 
      return
    }

    const { filterProducts:{products} } = data

    if (keys) products.sort((a, b) => keys.indexOf(a.key) - keys.indexOf(b.key))

    setProducts(products)
  }

  useEffect(() => {
    const controller = new AbortController(), signal = controller.signal
    loadProducts(signal)

    return () => controller.abort()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className={classes.cardGrid}>
    {products?.map(product => 
      <Product key={product.key} {...{ display, product }} />
    )}
  </div>
}

export default Products

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */