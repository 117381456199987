/* Next */
import Link from 'next/link'
import Image from 'next-image-export-optimizer'

/* Material */
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Swiper */
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
} from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

SwiperCore.use([
  Navigation,
  Pagination,
  Autoplay,
])

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* --------------------------------------------------------------------- */

    carouselWrapper: {
      position: 'relative',
      userSelect: 'none',

      width: '100%',
      maxWidth: 'calc(100vw - 32px)', // TODO: Wo kommen die 32px her?

      [theme.breakpoints.up('sm')]: {
        maxWidth: 'calc(100vw - 63px)', // TODO: Wo kommen die 63px her?
      },

      '& .swiper': {
        '& .swiper-button-prev, & .swiper-button-next': {
          '&:after': {
            color: theme.palette.primary.light,
          },
        },
        '& .swiper-pagination-bullet-active': {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },

    imageSwiper: {
      height: '250px',
      [theme.breakpoints.up('sm')]: {
        height: '450px',
      },
      '& .swiper-slide, & .swiper-zoom-container': {
        height: '100%',
        //cursor: 'pointer',

        '& img': {
          width: '100%',
          height: '100%',
        },
      },
    },

    href: {
      display: 'block',
      position: 'relative',
      width: '100%',
      height: '100%',
      color: 'inherit',
      textDecoration: 'inherit',
      '& .MuiCardContent-root': {
        paddingBottom: theme.spacing(1),
      },
    },

    /* --------------------------------------------------------------------- */
  })
)

export interface slideConfig {
  src: string
  alt: string
  href?: string
  position: string
}

const Carousel: React.FC<{ slideConfigs:slideConfig[] }> = ({slideConfigs}) => {
  const classes = useStyles()

  return <div className={classes.carouselWrapper}>
    <Swiper
      className={classes.imageSwiper}
      spaceBetween={50}
      slidesPerView={1}
      simulateTouch={false}
      cssMode

      autoplay={{
        delay: 5000,
      }}
      loop

      pagination={{ clickable: true }}
      navigation={true}
    >
      {slideConfigs.map(({src, alt, href, position}, index) => {
        
        const image = <Image
          {...{ src, alt }}
          fill
          sizes='100vw'
          loading='lazy'
          
          style={{
            objectFit: 'cover',
            objectPosition: position,
          }}
        />

        const slideContent = href
          ? <Link href={href} legacyBehavior>
              <a className={classes.href}>{image}</a>
            </Link>
          : image

        const slide = <SwiperSlide key={index} >
          {slideContent}
        </SwiperSlide>

        return slide
      })}
    </Swiper>
  </div>
}

export default Carousel

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */