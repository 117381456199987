import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { cardHover } from 'src/theme/customEffects'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    // ----------------------------------------------
    // Card

    cardMedia: {
      aspectRatio: '16/9',
    },

    cardColumn: {
      height: '100%',
      gridColumn: 'span 12',
      [theme.breakpoints.up('sm')]: { gridColumn: 'span 6' },
      [theme.breakpoints.up('md')]: { gridColumn: 'span 4' },
    },

    href: {
      color: 'inherit',
      textDecoration: 'inherit',
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',

      '&.hover': cardHover(theme),
    },
    cardContent: {
      flexGrow: 1,

      '& .card-product-title ul': {
        paddingLeft: 25,
      },
    },

    noShaddow: {
      boxShadow: 'none',
    },

    // ----------------------------------------------
    // Produkt

    price: {
      marginTop: theme.spacing(2),
      textAlign: 'right',
    },

    deliverytimeDisplay: {
      marginTop: theme.spacing(2),
      padding: 0,
      background: 'none',
      '& .MuiAlert-icon': {
        marginRight: theme.spacing(1),
      },
    },

    // ----------------------------------------------

  })
)

export default useStyles
