
export const formatedDate = (date?:Date|string) => {
  if (!date) date = new Date()
  if (typeof date === 'string') date = new Date(date)
  return date.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  })
}
export const formatedIsoDate = (date?:Date|string) => {
  if (!date) date = new Date()
  if (typeof date === 'string') date = new Date(date)
  return date.toISOString().substring(0, 10)
}

export const formatedTime = (date?:Date|string) => {
  if (!date) date = new Date()
  if (typeof date === 'string') date = new Date(date)
  return date.toLocaleTimeString('de-DE',)
}