/* Next */
import Link from 'next/link'
import Image from 'next-image-export-optimizer'

/* Material */
import Typo from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { lighten } from '@mui/material/styles'

/* Components */
//import BrLine from 'src/components/js_elements/BrLine'

/* Swiper */
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
} from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

SwiperCore.use([
  Navigation,
  Pagination,
  Autoplay,
])

const useStyles = makeStyles((theme: Theme) => {

  const bgColor = lighten(theme.palette.primary.light, 0.8)
  //const bgColor = lighten(theme.palette.secondary.light, 0.6)
  //const bgColor = theme.palette.grey[300]

  return createStyles({
    /* --------------------------------------------------------------------- */

    carouselWrapper: {
      position: 'relative',
      userSelect: 'none',

      width: '100%',
      maxWidth: 'calc(100vw - 32px)', // TODO: Wo kommen die 32px her?

      backgroundColor: bgColor,
      color: theme.palette.getContrastText(bgColor),

      [theme.breakpoints.up('sm')]: {
        maxWidth: 'calc(100vw - 63px)', // TODO: Wo kommen die 63px her?
      },

      '& .swiper': {
        '& .swiper-button-prev, & .swiper-button-next': {
          '&:after': {
            color: theme.palette.primary.light,
          },
        },
        '& .swiper-pagination-bullet-active': {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },

    imageSwiper: {
      [theme.breakpoints.up('md')]: {
        height: '450px',
      },
      '& .swiper-slide, & .swiper-zoom-container': {
        height: '100%',
        //cursor: 'pointer',
      },
    },

    /* --------------------------------------------------------------------- */

    slide2Cols: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(4),
      height: '100%',

      '& > div.horizontal, & > div.vertical': {
        position: 'relative',
        '& img': {
          width: '100%',
          height: '100%',
        },
      },

      '& > div.horizontal': {
        height: '100%',
        display: 'none',
      },
      '& > div.vertical': {
        width: '100%',
        aspectRatio: 1.7,
        maxHeight: 500,
      },

      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1fr',

        '& > div.horizontal, & > div.vertical': {
          '& img': {
            objectPosition: 'left',
          },
        },

        '& > div.horizontal': { display: 'flex' },
        '& > div.vertical': { display: 'none' },
      },

      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr',
      },

      '& > div.title': {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',

        padding: theme.spacing(4),

        [theme.breakpoints.up('md')]: {
          padding: 0,
          paddingRight: theme.spacing(4),
        },
      },
    },

    href: {
      color: 'inherit',
      textDecoration: 'inherit',
      '& .MuiCardContent-root': {
        paddingBottom: theme.spacing(1),
      },
    },

    /* --------------------------------------------------------------------- */
  })}
)

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

export interface slideConfig {
  title: string
  alt: string
  src: string
  href?: string
  subTitle: string
}

const Carousel: React.FC<{ slideConfigs:slideConfig[] }> = ({slideConfigs}) => {
  const classes = useStyles()

  const slides = slideConfigs.map(({ 
    src, alt, href, title, subTitle 
  },index) => {
    const slide = <div 
      key={index}
      className={classes.slide2Cols}
    >
      {['horizontal', 'vertical'].map(direction =>
        <div className={direction} key={direction}>
          <Image
            {...{ src, alt }}
            fill
            sizes='(min-width: 1200px) 460px,
                   (min-width: 900px) 50vw,
                   100vw'
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            loading='lazy'
          />
        </div>
      )}
      <div className='title'>
        <Typo component="div" variant="h4" color="inherit" gutterBottom>
          {title}
        </Typo>
        <Typo component="p" variant="h6" color="inherit" gutterBottom>
          {subTitle}
        </Typo>
      </div>
    </div>

    if (href) return <Link href={href} key={index} legacyBehavior>
      <a className={classes.href}>{slide}</a>
    </Link>

    return slide
  })

  return <div className={classes.carouselWrapper}>
    <Swiper
      className={classes.imageSwiper}
      spaceBetween={50}
      slidesPerView={1}
      simulateTouch={false}
      cssMode

      autoplay={{
        delay: 5000,
      }}
      loop

      pagination={{ clickable: true }}
      navigation={true}
    >
      {slides.map((slide, index) => 
        <SwiperSlide key={index} >
          {slide}
        </SwiperSlide>
      )}
    </Swiper>
  </div>
}

export default Carousel

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */