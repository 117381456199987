/* Next */
//import Head from 'next/head'

/* Material */
import Typo from '@mui/material/Typography'
import Container from '@mui/material/Container'
import useStyles from './styles'

/* Components */
import BrLine from 'src/components/js_elements/BrLine'

import CarouselStory, { slideConfig as storySlide } from 'src/components/Carousels/Story'
import CarouselMood,  { slideConfig as moodSlide }  from 'src/components/Carousels/Mood'

import Products from 'src/components/ProductPreviewRenders/ProductRow'

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const storySlides: storySlide[] = [
  {
    alt: 'Meraki',
    src: 'images/template/mood/meraki-mood.png',
    href: '/b/meraki/203',

    title: 'Meraki',

    subTitle: `
      Verleihen deinem Zuhause noch mehr Atmosphäre durch Meraki 
      Duftkerzen und Raumdüfte , die deine Sinne verwöhnen.
    `,
  },

  {
    alt: 'ph fragrances',
    src: 'images/template/mood/ph-fragrances-laundry-iris.png',
    href: '/b/ph-fragrances/205',

    title: 'ph fragrances',

    subTitle: `
      Verwöhnen Sie Ihre Sinne mit dem Duft des 
      außergewöhnlichen Waschmittel von ph fragrances.
    `,
  },
]

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const moodSlides: moodSlide[] = [
  {
    src: 'images/template/story/bondi-wash.png',
    alt: 'Bondi Wash-Dog Care aus der australischen Natur',
    href: '/b/bondi_wash/189/m/tierpflege/1116',
    position: 'top',
  },
  {
    src: 'images/template/story/marken.png',
    alt: 'Unsere Marken',
    href: '/brands',
    position: 'bottom',
  },
  {
    src: 'images/template/story/bjoerk-berries-2.png',
    alt: 'Björk & Berries - die ecoluxury Beauty-Marke aus Schweden',
    href: '/b/bjoerk_berries/190',
    position: 'center',
  },
]

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const Home = () => {
  const classes = useStyles()

  return <Container maxWidth='lg' className={classes.container}>

    <CarouselMood slideConfigs={moodSlides} />

    {/* --------------------------------------------------------------------- */}
    <hr />

    <Products
      display={['title', 'center', 'no-card']}
      size={6}
      keys={[
        '20078-113650', // meraki Raumduft - Verbena drizzle
        '20083-113656', // meraki Duftkerze - Fig & apricot
        '20075-113645', // meraki Handseife - Silky mist
        '14689-101378', // Bondi Wash Hand Lotion Tasmanian Pepper & Lavender
        '19036-111001', // Claus Porto Alface Green Leaf Seife
        '15069-101885', // Björk & Berries Natural Glow Oil Shimmering
      ]}
    />

    {/* --------------------------------------------------------------------- */}
    <hr />

    <CarouselStory slideConfigs={storySlides} />

    {/* --------------------------------------------------------------------- */}
    <hr />

    <Typo 
      className={classes.homeTextTitle}
      component='h2' variant='h4' color='inherit' gutterBottom
    >
      <BrLine>Dein Online Shop für Home</BrLine> & <BrLine>Living Produkte</BrLine>
    </Typo>
    
      <Typo
        className={classes.homeText}
        component="p"
        variant="body2"
        color="inherit"
        paragraph
      >
        Willkommen bei homeylove – Deinem Online-Shop für stilvolles und
        gemütliches Wohnen sowie Pflegeprodukte für Körper und Geist. Bei uns
        findest Du eine riesige Auswahl hochwertigen Produkten aus den Bereichen
        Geschirr, Home & Living und Body & Care. Wir bieten Dir eine
        handverlesene Auswahl von Top-Marken.
      </Typo>
      <Typo
        className={classes.homeText}
        component="p"
        variant="body2"
        color="inherit"
        paragraph
      >
        Entdecke bei uns alles, was Dein Zuhause noch ein Stück gemütlicher
        macht. Ob Bettwäsche, Dekokissen, Nachtwäsche, Frottiertücher und
        Badaccessoires für Dein Bad oder auch Pflegeprodukte mit natürlichen
        Inhaltsstoffen – bei uns wirst Du garantiert fündig. Mit unseren
        Produkten kannst Du Dir Dein eigenes kleines Paradies schaffen und Dich
        rundum wohlfühlen.
      </Typo>
      <Typo
        className={classes.homeText}
        component="p"
        variant="body2"
        color="inherit"
        paragraph
      >
        Unsere Liebe zum Detail spiegelt sich in jedem Produkt wider. Wir legen
        Wert auf Qualität, Design und Nachhaltigkeit. So findest Du bei uns zum
        Beispiel auch Produkte aus recycelten Materialien oder von Marken, die
        sich für den Umweltschutz engagieren.
      </Typo>
      <Typo
        className={classes.homeText}
        component="p"
        variant="body2"
        color="inherit"
        paragraph
      >
        Bei homeylove kannst Du bequem von zuhause aus shoppen und Dich von
        unseren vielfältigen Produkten inspirieren lassen. Wir liefern schnell
        und zuverlässig zu Dir nach Hause. Und sollte einmal etwas nicht Deinen
        Erwartungen entsprechen, kannst Du von unserem unkomplizierten
        Rückgaberecht Gebrauch machen.
      </Typo>
      <Typo
        className={classes.homeText}
        component="p"
        variant="body2"
        color="inherit"
        paragraph
      >
        Tauche ein in die Welt von homeylove und lass Dich von unseren
        hochwertigen Produkten begeistern. Wir freuen uns darauf, Dein Zuhause
        noch ein Stück gemütlicher zu machen.
      </Typo>

  </Container>
}

export default Home