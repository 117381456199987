/* Material */
import Alert from '@mui/material/Alert'
import useStyles from './styles'

/* Material-Icons */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

interface Props {
  deliverytime?: number
  stock?: number
  onlyStock?: true
}

/* Config */
import config from 'src/config'

const DeliverytimeDisplay: React.FC<Props> = ({ deliverytime, stock=0, onlyStock }) => {
  const classes = useStyles()

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* Artikel nur noch Lagerware */
  if (onlyStock && stock <= config.onlyStockWarningLimit)
    return <Alert 
        className={classes.deliverytimeDisplay}
        severity="warning" 
        icon={<ErrorOutlineIcon />}
      >
        nur noch {stock} x lieferbar
      </Alert>

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* Artikel Bestellbar und auf Lager */
  if (stock)
    return <Alert 
        className={classes.deliverytimeDisplay}
        severity="success"
      >
        { stock <= config.stockFullQty && <>
          {stock} x {' '}
        </>}
        sofort lieferbar
      </Alert>

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /* Ganz normale Lieferzeit */
  return <Alert 
      className={classes.deliverytimeDisplay}
      severity="info" 
    >
      Lieferzeit: {deliverytime} Tage*²
    </Alert>

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  
}

export default DeliverytimeDisplay
